import * as React from 'react'
import { useTheme } from 'emotion-theming'

import styledSystemCss from '@styled-system/css'
import { Global, css } from '@emotion/core'

import { Fonts } from './fonts'

function CssReset() {
  return (
    <Global
      styles={css`
        *,
        ::before,
        ::after {
          box-sizing: border-box;
        }

        a {
          text-decoration: inherit;
          color: inherit;
          cursor: pointer;
        }

        html,
        body,
        div,
        span,
        applet,
        object,
        iframe,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        blockquote,
        pre,
        a,
        abbr,
        acronym,
        address,
        big,
        cite,
        code,
        del,
        dfn,
        em,
        img,
        ins,
        kbd,
        q,
        s,
        samp,
        small,
        strike,
        strong,
        sub,
        sup,
        tt,
        var,
        b,
        u,
        i,
        center,
        dl,
        dt,
        dd,
        ol,
        ul,
        li,
        fieldset,
        form,
        label,
        legend,
        table,
        caption,
        tbody,
        tfoot,
        thead,
        tr,
        th,
        td,
        article,
        aside,
        canvas,
        details,
        embed,
        figure,
        figcaption,
        footer,
        header,
        hgroup,
        main,
        menu,
        nav,
        output,
        ruby,
        section,
        summary,
        time,
        mark,
        audio,
        video,
        hr {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
        }
        #cc-button {
          display: none;
        }

        /* HTML5 display-role reset for older browsers */
        article,
        aside,
        details,
        figcaption,
        figure,
        footer,
        header,
        hgroup,
        main,
        menu,
        nav,
        section {
          display: block;
        }

        /* HTML5 hidden-attribute fix for newer browsers */
        *[hidden] {
          display: none;
        }

        body {
          line-height: 1;
        }

        ol,
        ul {
          list-style: none;
        }

        blockquote,
        q {
          quotes: none;
        }

        blockquote:before,
        blockquote:after,
        q:before,
        q:after {
          content: '';
          content: none;
        }

        table {
          border-collapse: collapse;
          border-spacing: 0;
        }
      `}
    />
  )
}

function CssBase() {
  const theme = useTheme()
  return (
    <Global
      styles={css`
        body {
          ${styledSystemCss({
            backgroundColor: 'g-bg',
            color: 'g-text',
            fontFamily: 'body',
            fontSize: [2, 3],
          })({ theme })}
        }

        b,
        strong {
          font-weight: 700;
        }

        i,
        em {
          font-style: italic;
        }

        p {
          line-height: 1.25;
        }
      `}
    />
  )
}

// This font-face declaration is to override the font-family name declared
// by Typekit to 'futura-pt' - the name used in the theme, so that any styles
// using font-weight: bold or 700 will use this dedicated font for bold styles.
function FontFace() {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'futura-pt';
          src: url('https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
              format('woff2'),
            url('https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
              format('woff'),
            url('https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
              format('opentype');
          font-display: auto;
          font-style: normal;
          font-weight: 700;
        }
      `}
    />
  )
}

export function GlobalStyles() {
  return (
    <React.Fragment>
      <CssReset />
      <CssBase />
      <Fonts />
      <FontFace />
    </React.Fragment>
  )
}
