/* eslint-disable emotion/no-vanilla */
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { hydrate } from 'emotion'
import { ssrExchange } from 'urql'

import { loadableReady } from '@loadable/component'

import * as Utils from './utils'
import * as Suspense from './suspense'
import { App } from './app'
import { GlobalProvider } from './global-provider'
import { createStore } from './create-store'

const store = createStore({ initialState: window.__APP_DATA__ })
const ssrCache = ssrExchange({
  initialState: window.__GQL_DATA__,
  isClient: true,
})

async function render({ App, GlobalProvider, store }) {
  const element = document.querySelector('[data-app-root]')

  if (window.__EMOTION_IDS__) {
    hydrate(window.__EMOTION_IDS__)
  }

  loadableReady(() => {
    ReactDOM.hydrate(
      <GlobalProvider
        initialState={window.__STATE__}
        ssrCache={ssrCache}
        store={store}
      >
        <Suspense.Boundary>
          <App />
        </Suspense.Boundary>
      </GlobalProvider>,
      element
    )
  })
}

render({ App, GlobalProvider, store })

// This logs the window type to identify when the app was rendered in an iframe
// TODO remove this attribute when memory leak on client side is fixed.
// https://app.clickup.com/t/2574244/PLF-1795
Utils.NewRelic.maybeNewrelic(newrelic => {
  newrelic.setCustomAttribute(
    'windowType',
    window.top === window.self ? 'top' : 'subframe'
  )
})

if (module.hot) {
  let App = require('./app/index.js').App
  let GlobalProvider = require('./global-provider.js').GlobalProvider
  let createStore = require('./create-store.js').createStore
  let prevStore = store
  let nextStore = createStore({ initialState: prevStore.getState() })

  module.hot.accept('./app/index.js', () => {
    App = require('./app/index.js').App
    render({ App, GlobalProvider, store: prevStore })
  })

  module.hot.accept('./create-store.js', () => {
    createStore = require('./create-store.js').createStore
    prevStore = nextStore
    nextStore = createStore({ initialState: prevStore.getState() })

    render({ App, GlobalProvider, store: nextStore })
  })

  module.hot.accept('./global-provider.js', () => {
    GlobalProvider = require('./global-provider.js').GlobalProvider

    render({ App, GlobalProvider, store: prevStore })
  })
}
