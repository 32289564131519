import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import React from 'react'

import * as Constants from './constants'
import * as Player from './player'
import { useSafeReplaceQuery } from './use-safe-replace-query'

/**
 * A hook to enforce opening of the user's wallet if the appropriate flag
 * (enforceDeposit) is true. The wallet should open only once after the
 * flag is set.
 */
export function useDepositEnforcing() {
  const history = ReactRouter.useHistory()
  const enforceDeposit = ReactRedux.useSelector(state =>
    Player.getEnforceDeposit(state.player)
  )
  const dispatch = ReactRedux.useDispatch()

  const safeQuery = useSafeReplaceQuery({
    wallet: Constants.TransactionType.DEPOSIT,
    login: null,
  })

  React.useEffect(() => {
    if (enforceDeposit) {
      history.push({
        search: safeQuery,
      })
      dispatch(Player.updateEnforceDeposit(false))
    }
  }, [enforceDeposit])
}
