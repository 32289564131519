import * as R from 'ramda'
import * as reselect from 'reselect'
import { bind } from 'redux-effects'

import * as api from '@rushplay/api-client'
import * as notifications from '@rushplay/notifications'
import { SESSION_EXPIRED } from '@rushplay/websockets'
import { session, types } from '@rushplay/session'

import * as cookies from './cookies'

export const SESSION_UPDATED = types.UPDATE_SESSION

const INITIAL_STATE = {
  createdAt: null,
  token: null,
}

export function update(token = null, createdAt = null) {
  return bind(
    cookies.set('token', token, {
      // When this cookie expires and is removed by a browser, it forces
      // GraphQL client to reinitialise. As a result it reinitialises entire
      // application and causes issues like gameplay interruption. As a
      // workaround the `max-age` of the cookie is set to be longer than any of
      // player sessions instead of previous value of 1 hour.
      maxAge: 24 * 60 * 60 * 1000,
      httpOnly: false,
      path: '/',
    }),
    () => ({
      type: SESSION_UPDATED,
      payload: {
        createdAt,
        token,
      },
    })
  )
}

export function fetch(token) {
  return api.fetchSession({
    token,
    success: response =>
      update(response.value.token, response.value.loggedInAt),
    failure: () => update(),
    version: 1,
  })
}

export function invalidate() {
  return [
    update(),
    bind(
      // Hey Vsauce, Leo here. What is so special about getting an Oreo cookie
      // in Boom’s codebase? What if I say the cookie in question does not even
      // matter?
      //
      // When we destroy session and dispatch a notification synchronously,
      // Framer Motion is not aware entire DOM have just gone to hell as we
      // reload app’s configuration on session change. What happens? It acts
      // naughty by throwing errors about unreachable DOM nodes it wants to
      // animate.
      //
      // What do we do then? We dispatch notification on next tick giving
      // Framer Motion just a bit more time to realise that when DOM packed
      // suitcase and said it just goes to buy some cigarettes... you know.
      //
      // It ain’t coming back, son. Move on.
      cookies.get('OREO'),
      () => notifications.add({ message: 'session-expired', level: 'info' })
    ),
  ]
}

/**
 * This action checks if the session is still alive or not.
 * If it's dead we update state.
 * on success we do nothing, as updating would trigger uneccesary re-renders and re-fetches.
 */
export function keepAlive(token) {
  return api.fetchSession({
    token,
    failure: invalidate,
    version: 2,
  })
}

export function saveLanguagePreference(language) {
  return api.saveLanguagePreference(language, {
    success: res => {
      return session.update({ player: res.value })
    },
    version: 1,
  })
}

/**
 * Extended `@rushplay/session` reducer
 * @param {SessionState} state
 * @param {FSA} action
 */
export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SESSION_EXPIRED:
    case SESSION_UPDATED: {
      const createdAt = R.path(['payload', 'createdAt'], action)
      const token = R.path(['payload', 'token'], action)

      if (token) {
        return { createdAt, token }
      }

      return INITIAL_STATE
    }

    default: {
      return state
    }
  }
}

export function getToken(state) {
  return R.pathOr(null, ['token'], state)
}

export function getCreatedAt(state) {
  return R.pathOr(null, ['createdAt'], state)
}

export const isAuthenticated = reselect.createSelector([getToken], token =>
  Boolean(token)
)
