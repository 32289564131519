import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import * as Configuration from './configuration'
import * as ServerConfiguration from './server-configuration'

function useAvailableLocales() {
  const configuration = ServerConfiguration.useContext()
  return R.reduce(
    (locales, country) => R.concat(locales, R.pluck('slug', country.locales)),
    [],
    configuration.countries
  )
}

export function SeoHreflangs() {
  const availableLocales = useAvailableLocales()
  const location = ReactRouter.useLocation()
  const origin = ReactRedux.useSelector(state =>
    Configuration.getOrigin(state.configuration)
  )

  return (
    <Helmet>
      {availableLocales.map(locale => (
        <link
          key={locale}
          rel="alternate"
          hrefLang={locale}
          href={`${origin}/${locale}${location.pathname}${location.search}`}
        />
      ))}
      <link rel="alternate" hrefLang="x-default" href={origin} />
    </Helmet>
  )
}
