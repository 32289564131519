import React from 'react'

import { ExternalFont } from './external-font'

export function Fonts() {
  return <ExternalFont href="https://use.typekit.net/ohs1snc.css" />
}

// for @loadable/components
export default Fonts
