import PropTypes from 'prop-types'

import * as I18n from './i18n'

const i18nKeys = {
  errorAuthFailed: 'login.authorization-failed-error',
  invalidCredentials: 'errors.login.invalid-credentials',
  timeoutLock: 'errors.login.timeout-lock',
  selfExclusionLock: 'errors.login.self-exclusion-lock',
}

/**
 * This helper transforms error object to appropriate translation key
 * @todo Make i18n-friendly error classes in Herz issue => https://github.com/RushPlay/frontend-platform/issues/37
 * @param {object} error
 * @return {string}
 */

export function normalizeAuthenticationError(error) {
  if (!error) {
    return null
  }

  if (error.name === 'LockedError') {
    if (error.reason === 'self_exclusion') {
      return i18nKeys.selfExclusionLock
    }

    if (error.reason === 'timeout-lock') {
      return i18nKeys.timeoutLock
    }

    return I18n.toErrorKey(error.reason)
  }

  if (error.message === 'Invalid credentials') {
    return i18nKeys.invalidCredentials
  }

  // General authentication failed error
  return i18nKeys.errorAuthFailed
}

normalizeAuthenticationError.propTypes = {
  error: PropTypes.object.isRequired,
}
