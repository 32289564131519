import * as ReactRedux from 'react-redux'
import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as Locks from '@rushplay/compliance/locks'
import * as ShuftiPro from '@rushplay/shufti-pro'
import { lazy as loadable } from '@loadable/component'

import * as ServerConfiguration from '../server-configuration'
import * as Session from '../session'
import { ClipPaths } from '../clip-paths'
import { CookieConsentBanner } from '../cookie-consent-banner'
import { GlobalErrorBoundary } from '../global-error-boundary'
import { GlobalStyles } from '../global-styles'
import { NotificationsContainer } from '../notifications'
import { SeoCanonicals } from '../seo-canonicals'
import { SeoHreflangs } from '../seo-hreflangs'
import { SeoMetadata } from '../seo-metadata'
import { SupportChat } from '../support-chat'
import { useCookieConsent } from '../use-cookie-consent'
import { useCountryCodeListener } from '../use-configuration-listener'
import { useCssCustomProperties } from '../use-css-custom-properties'
import { useDepositEnforcing } from '../use-deposit-enforcing'
import { useGoogleDataLayerManager } from '../use-google-data-layer-manager'
import { useOfferClaimedListener } from '../use-offer-claimed-listener'
import { usePageview } from '../use-pageview'
import { usePlayerListener } from '../use-player-listener'
import { useProfileRedirect } from '../use-profile-redirect'
import { useReturningPlayerListener } from '../use-returning-player-listener'
import { useSessionListener } from '../use-session-listener'
import { useWebSocketListener } from '../use-web-socket-listener'

import { useLocaleRedirect } from './use-locale-redirect'

const LocksContainer = loadable(() => import('./locks'))
const Primary = loadable(() => import('./primary'))
const BlockedMarket = loadable(() => import('./blocked-market'))

function useAppView() {
  const { country } = ServerConfiguration.useContext()
  const hasLocks = ReactRedux.useSelector(state => Locks.hasLocks(state.locks))
  const authenticated = ReactRedux.useSelector(state =>
    Session.isAuthenticated(state.session)
  )
  const location = ReactRouter.useLocation()
  const redirected = useLocaleRedirect()

  if (location.pathname === '/callbacks/shufti-pro') {
    return <ShuftiPro.Callback />
  }

  if (redirected) {
    return null
  }

  if (country.access === 'BLOCKED') {
    return <BlockedMarket />
  }

  if (hasLocks && authenticated) {
    return <LocksContainer />
  }

  return <Primary />
}

/**
 * Topmost application component. Shall be used for setting up global
 * application capabilities and to render subapplications (main, locks,
 * language picker, etc.) based on necessary conditions.
 */
export function App() {
  useCountryCodeListener()
  useCssCustomProperties()
  useDepositEnforcing()
  useProfileRedirect()
  useGoogleDataLayerManager()
  useOfferClaimedListener()
  usePageview()
  usePlayerListener()
  useReturningPlayerListener()
  useSessionListener()
  useWebSocketListener()

  const children = useAppView()
  const cookieConsentEnabled = useCookieConsent()

  return (
    <GlobalErrorBoundary>
      <React.Fragment>
        <SeoMetadata />
        <SeoCanonicals />
        <SeoHreflangs />
        <ClipPaths />
        <GlobalStyles />
        <Common.Box
          display="grid"
          minHeight="fill-available"
          gridTemplateColumns={['minmax(0,1fr)', null, '200px minmax(0,1fr)']}
          gridTemplateRows="auto 1fr"
        >
          {children}
        </Common.Box>

        <NotificationsContainer />
        <SupportChat />
        {cookieConsentEnabled && <CookieConsentBanner />}
      </React.Fragment>
    </GlobalErrorBoundary>
  )
}
