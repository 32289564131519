import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as locks from '@rushplay/compliance/locks'
import * as offers from '@rushplay/offers'
import * as Triggers from '@rushplay/triggers'
import * as Herz from '@rushplay/herz'

import * as Analytics from './analytics'
import * as ReactRedux from './session'
import * as player from './player'
import * as promotions from './promotions'
import * as Inventory from './inventory'

/**
 * Maintain player-dependent state up-to-date
 */
export function useSessionListener() {
  const dispatch = useDispatch()
  const affiliateId = useSelector(state => player.getAffiliateId(state.player))
  const session = Herz.Auth.useSession()

  // Fetch data on token change
  React.useEffect(() => {
    if (session.token) {
      dispatch([
        player.fetchPlayerInfo(),
        Analytics.analyticsRegistration(),
        locks.fetch(),
        offers.fetchDepositOffers({ affiliateId }),
        promotions.fetch(),
        Inventory.fetch(),
        Triggers.fetch(),
      ])
    }
  }, [session.token])

  React.useEffect(() => {
    function handleKeepAlive() {
      if (!document.hidden) {
        dispatch(ReactRedux.keepAlive(session.token))
      }
    }

    if (session.token) {
      // Setup listener for tab-change and pauses. (example: closing lid of laptop)
      document.addEventListener('visibilitychange', handleKeepAlive)

      return () => {
        document.removeEventListener('visibilitychange', handleKeepAlive)
      }
    }
  }, [session.token])
}
