import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as I18n from '@rushplay/i18n'
import {
  SupportChat as Component,
  getTabVisibility,
} from '@rushplay/support-chat'

import * as Player from './player'
import * as ServerConfiguration from './server-configuration'

export function SupportChat(props) {
  const i18n = I18n.useI18n()

  const {
    liveChatDepartment,
    liveChatKey,
    locale,
  } = ServerConfiguration.useContext()

  const player = ReactRedux.useSelector(state => ({
    birthdate: Player.getBirthdate(state.player),
    valueSegment: Player.getValueSegment(state.player),
    name: Player.getDisplayName(state.player),
    username: Player.getUsername(state.player),
    email: Player.getEmail(state.player),
  }))

  const data = React.useMemo(
    () => ({
      birthDate: player.birthDate ? `DOB: ${player.birthdate}` : null,
      departmentId: R.pathOr(null, ['primary'], liveChatDepartment),
      fallbackDepartmentId: R.pathOr(null, ['fallback'], liveChatDepartment),
      name: player.name ? `${player.name} (${player.username})` : '',
      tags: R.filter(item => !R.isNil(item), [
        player.birthdate ? `DOB: ${player.birthdate}` : null,
        player.valueSegment ? `Value segment: ${player.valueSegment}` : null,
      ]),
    }),
    [
      player.name,
      player.username,
      player.birthdate,
      player.valueSegment,
      liveChatDepartment,
    ]
  )

  const translations = React.useMemo(
    () => ({
      'chat.concierge.avatarPath': i18n.translate('chat.concierge.avatar'),
      'chat.concierge.name': i18n.translate('chat.concierge.name'),
      'chat.concierge.title': i18n.translate('chat.concierge.title'),
      'chat.offlineForm.greeting': i18n.translate('chat.offline-form.greeting'),
      'chat.title': i18n.translate('chat.title'),
      'launcher.chatLabel': i18n.translate('chat.launcher.chat-label'),
      'launcher.label': i18n.translate('chat.launcher.label'),
    }),
    [
      i18n.translate('chat.concierge.avatar'),
      i18n.translate('chat.concierge.name'),
      i18n.translate('chat.concierge.title'),
      i18n.translate('chat.offline-form.greeting'),
      i18n.translate('chat.title'),
      i18n.translate('chat.launcher.chat-label'),
      i18n.translate('chat.launcher.label'),
    ]
  )

  if (!liveChatKey) {
    return null
  }

  return (
    <Chat
      {...props}
      addScriptInHead
      appKey={liveChatKey}
      departmentId={data.departmentId}
      email={player.email}
      fallbackDepartmentId={data.fallbackDepartmentId}
      language={locale.language}
      mountPoint="supportChat"
      name={data.name}
      tags={data.tags}
      hidden={props.hidden}
      translations={translations}
    />
  )
}

SupportChat.defaultProps = {
  hidden: true,
}

SupportChat.propTypes = {
  hidden: PropTypes.bool,
}

function Chat(props) {
  const [allowLoad, setAllowLoad] = React.useState(false)
  const isVisible = ReactRedux.useSelector(state =>
    getTabVisibility(state.supportChat)
  )

  React.useEffect(() => {
    setAllowLoad(isVisible)

    return () => setAllowLoad(false)
  }, [isVisible])

  if (allowLoad) {
    return <Component {...props} />
  }

  return null
}
