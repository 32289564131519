import * as React from 'react'

import * as Common from '@rushplay/common'
import * as notifications from '@rushplay/notifications'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { useI18n } from '@rushplay/i18n'

import { HtmlContent } from './html-content'
import { Toast } from './toast'

const bounceAnimation = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  30% {
    transform: translateX(-5%);
    opacity: 1;
  }

  40% {
    transform: translateX(0);
    opacity: 1;
  }


  60% {
    transform: translateX(-1%);
    opacity: 1;
  }

 75%{
  transform: translateX(0);
  opacity: 1;
 }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const Transition = styled.div`
  animation-name: ${bounceAnimation};
  animation-duration: 1.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`

export function NotificationsContainer() {
  const i18n = useI18n()

  return (
    <Common.Box
      position="fixed"
      top={['48px', '0px']}
      right="0px"
      width={['100%', 'auto']}
      minWidth={[null, '350px']}
      maxWidth="420px"
      zIndex="9999"
    >
      <notifications.Notifications mountPoint="notifications">
        {notificationIds => (
          <>
            {notificationIds.map(notificationId => (
              <Transition key={notificationId}>
                <notifications.Notification
                  id={notificationId}
                  mountPoint="notifications"
                >
                  {notification => (
                    <Common.Space pt={1} px={1}>
                      <Toast
                        variant={notification.level}
                        title={
                          notification.level
                            ? i18n.translate(`toast.${notification.level}`)
                            : 'Title'
                        }
                        onDismiss={() => notification.onDismiss(notificationId)}
                      >
                        <HtmlContent
                          html={{
                            __html: notification.contentTranslated
                              ? notification.message
                              : notification.message
                              ? i18n.translate(
                                  notification.message,
                                  notification.variables
                                )
                              : 'Content',
                          }}
                        />
                      </Toast>
                    </Common.Space>
                  )}
                </notifications.Notification>
              </Transition>
            ))}
          </>
        )}
      </notifications.Notifications>
    </Common.Box>
  )
}
