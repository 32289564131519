import * as Herz from '@rushplay/herz'

export function toErrorKey(key) {
  if (!key) {
    return 'errors.general.unknown'
  }

  return `errors.${Herz.Utils.String.toKebabCase(key)}`
}

export function toTranslationKey(keysArray) {
  return keysArray.map(key => Herz.Utils.String.toKebabCase(key)).join('.')
}
