/**
 * Function that waits for newrelic instance to be initiated and available on
 * `window` before calling newrelic methods.
 * @param {Function} fn callback which is passed the newrelic instance as an argument.
 * @param {Number} attempt loop counter.
 */
export function maybeNewrelic(fn, attempt = 1) {
  if (typeof window === 'undefined') {
    return
  }

  if (window.newrelic != null) {
    fn(window.newrelic)
    return
  }

  if (attempt >= 10) {
    return
  }

  setTimeout(() => maybeNewrelic(fn, attempt + 1), 500 * attempt)
}
