import * as ReactRedux from 'react-redux'
import React from 'react'
import * as Herz from '@rushplay/herz'
import * as Player from './player'

/**
 * @param {Object} variables Variables for Google Tag Manager
 */
function updateDataLayer(variables) {
  if (window.dataLayer) {
    window.dataLayer.push(variables)
  } else {
    // eslint-disable-next-line no-console
    console.error(`"window.dataLayer" is not defined`)
  }
}

/**
 * A hook that pushes variables to an existing Google data layer.
 *
 * A requirment for using this hook is that 'window.dataLayer' must be defined.
 */
export function useGoogleDataLayerManager() {
  const session = Herz.Auth.useSession()
  const affiliateId = ReactRedux.useSelector(state =>
    Player.getAffiliateId(state.player)
  )
  const prevAuthenticated = React.useRef(session.authenticated)
  const userId = ReactRedux.useSelector(state =>
    Player.getUsername(state.player)
  )

  const __PROD__ = process.env.NODE_ENV === 'production'

  // Hook that updates 'user.uid' for an authenticated player
  React.useEffect(() => {
    if (__PROD__) {
      if (session.authenticated) {
        if (userId) {
          updateDataLayer({ 'user.uid': userId })
        }
      }
    }
  }, [session.authenticated, userId])

  // Hook that updates 'user.affiliateid' for an authenticated player
  React.useEffect(() => {
    if (__PROD__) {
      if (session.authenticated) {
        if (affiliateId) {
          updateDataLayer({ 'user.affiliateid': affiliateId })
        }
      }
    }
  }, [affiliateId, session.authenticated])

  React.useEffect(() => {
    if (session.authenticated) {
      prevAuthenticated.current = true
    }
  }, [session.authenticated, prevAuthenticated])

  // Hook that updates 'user.affiliateid' and 'user.uid' for an terminated session
  React.useEffect(() => {
    if (__PROD__) {
      if (!session.authenticated && prevAuthenticated.current) {
        prevAuthenticated.current = false
        updateDataLayer({
          'user.affiliateid': undefined,
          'user.uid': undefined,
        })
      }
    }
  }, [session.authenticated, prevAuthenticated])
}
