import * as R from 'ramda'
import * as DateFns from 'date-fns'

import * as Api from '@rushplay/api-client'
import * as Websockets from '@rushplay/websockets'

import * as Utils from './utils'

// Constants
export const CLEAR = 'inventory/CLEAR'
export const MARK_ALL_AS_SEEN = 'inventory/MARK_ALL_AS_SEEN'
export const MARK_AS_SEEN = 'inventory/MARK_AS_SEEN'
export const REMOVE = 'inventory/REMOVE'
export const UPDATE_ALL = 'inventory/UPDATE_ALL'
export const UPDATE = 'inventory/UPDATE'

// Actions
export function clear() {
  return { type: CLEAR }
}

export function updateAll(payload) {
  return {
    type: UPDATE_ALL,
    payload: R.reduce(
      (acc, item) =>
        R.includes(R.path(['type'], item), [
          'FeatureTriggers',
          'FreeGamesVoucher',
          'Freebet',
          'Freespins',
        ])
          ? R.assoc(
              R.path(['id'], item),
              R.pick(
                [
                  'active',
                  'activeFrom',
                  'amount',
                  'betAmountCents',
                  'blitz',
                  'createdAt',
                  'expiresAt',
                  'gameDisabledForClientType',
                  'gameId',
                  'gameImage',
                  'gameProvider',
                  'gameTitle',
                  'id',
                  'redirectUrl',
                  'seen',
                  'spinValueMinMax',
                  'turnoverCentsLeft',
                  'type',
                  'zeroWagering',
                ],
                item
              ),
              acc
            )
          : acc,
      {},
      payload
    ),
  }
}

export function update(item) {
  return {
    type: UPDATE,
    payload: item,
  }
}

export function remove(id) {
  return {
    type: REMOVE,
    payload: id,
  }
}

export function markAllAsSeen() {
  return { type: MARK_ALL_AS_SEEN }
}

export function markAsSeen(id) {
  return {
    type: MARK_AS_SEEN,
    payload: id,
  }
}

export function fetch() {
  return Api.fetchInventory({
    success: res => updateAll(res.value),
    version: 1,
  })
}

// Reducer
export function reducer(state = {}, action) {
  switch (action.type) {
    case Websockets.SESSION_EXPIRED:
    case CLEAR: {
      return {}
    }

    case UPDATE_ALL: {
      return action.payload
    }

    case REMOVE: {
      return R.dissoc(action.payload, state)
    }

    case Websockets.NEW_INVENTORY_ITEM_ADDED:
    case UPDATE: {
      return R.includes(R.path(['type'], action.payload), [
        'Freebet',
        'FeatureTriggers',
        'Freespins',
      ])
        ? R.assoc(R.path(['id'], action.payload), action.payload, state)
        : state
    }

    case MARK_ALL_AS_SEEN: {
      return Utils.Objects.mapAssoc('seen', true, state)
    }

    case MARK_AS_SEEN: {
      return R.assocPath([action.payload, 'seen'], true, state)
    }

    default:
      return state
  }
}

// Selectors
export function getActiveItems(state, props) {
  return R.pipe(
    R.reject(
      promotion =>
        props.dateNow <= DateFns.getTime(DateFns.parseISO(promotion.activeFrom))
    ),
    R.values,
    R.sortBy(R.path(['activeFrom'])),
    R.reverse
  )(state)
}

export function getFutureItems(state, props) {
  return R.pipe(
    R.reject(
      promotion =>
        props.dateNow > DateFns.getTime(DateFns.parseISO(promotion.activeFrom))
    ),
    R.values,
    R.sortBy(R.path(['activeFrom'])),
    R.reverse
  )(state)
}

export function hasUnseenItems(state) {
  return R.findIndex(R.pathEq(['seen'], false), R.values(state)) >= 0
}

export function getUnseenItemsCount(state) {
  return R.pipe(R.values, R.filter(R.pathEq(['seen'], false)), R.length)(state)
}
