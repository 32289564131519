export const Brand = 'amuletobet'

export const ClientType = {
  BROWSER: 'browser',
  MOBILE_BROWSER: 'mobile-browser',
  UNKNOWN: 'unknown',
}

export const CookieKeys = {
  AB_USER_ID: 'ab_user_id',
  CLIENT_TYPE: 'client_type',
  COOKIE_CONSENT: 'cookie-consent',
  COUNTRY_CODE: 'country_code',
  DEVICE_PIXEL_RATIO: 'device_pixel_ratio',
  LANGAUGE: 'language',
  OFFER_CLAIMED: 'offer_claimed',
  RETURNING_PLAYER: 'returning_player',
  STORE_SEEN: 'has_seen_inventory_store',
  TOKEN: 'token',
  ZENDESK_CACHED: 'zendesk_cached',
}

export const CurrencyCodes = ['EUR', 'CAD', 'mBTC', 'BRL']

export const EmailPattern = '^\\S+@\\S+\\.\\S{2,}$'
export const EnglishAndAccentedLettersPattern =
  '^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF0-9\\-$@$!%*?&#^-_.+][a-zA-Z\u00C0-\u024F\u1E00-\u1EFF0-9\\-$@$!%*?&#^-_. +]*$'

export const ENVS = {
  staging: 'stg',
  production: 'prd',
}

export const GamblingLicenseIds = {
  CURACAO: 'curacao',
  MGA: 'mga',
  SGA: 'sga',
}

export const GameTileHeight = {
  LARGE: 435,
  MEDIUM: 306,
  SMALL: 187,
}

export const GAME_TILE_MULTIPLIER = 0.55

export const GameTileProviderLogosWidths = {
  '1-x-2-gaming': ['56px', '72px'],
  '2-by-2-gaming': ['64px', '80px'],
  '4-the-player': ['100px', '100px'],
  'all-41-studios': ['100px', '100px'],
  amatic: ['100px', '100px'],
  bally: ['48px', '56px'],
  barcrest: ['48px', '56px'],
  'big-time-gaming': ['72px', '88px'],
  blueprint: ['48px', '56px'],
  'blueprint-gaming': ['48px', '56px'],
  booming: ['48px', '56px'],
  'booming-games': ['48px', '56px'],
  cayetano: ['48px', '56px'],
  'chance-interactive': ['100px', '100px'],
  'crazy-tooth-studio': ['100px', '100px'],
  'dice-lab': ['100px', '100px'],
  'elk-studios': ['56px', '72px'],
  evolution: ['96px', '112px'],
  fantasma: ['100px', '100px'],
  felt: ['48px', '56px'],
  'felt-gaming': ['48px', '56px'],
  'fortune-factory-studios': ['73px', '73px'],
  foxium: ['100px', '100px'],
  gamatron: ['64px', '80px'],
  gameburger: ['80px', '96px'],
  'gameburger-studios': ['80px', '96px'],
  gamomat: ['64px', '80px'],
  gig: ['40px', '48px'],
  'golden-rock': ['96px', '112px'],
  'golden-hero-games': ['100px', '100px'],
  'golden-rock-studios': ['96px', '112px'],
  'green-jade': ['100px', '100px'],
  habanero: ['72px', '88px'],
  hacksaw: ['64px', '80px'],
  'high-5': ['72px', '88px'],
  'high-5-games': ['72px', '88px'],
  'inspired-gaming': ['100px', '100px'],
  'iron-dog-studio': ['100px', '100px'],
  jftw: ['64px', '80px'],
  kalamba: ['56px', '72px'],
  'kalamba-games': ['56px', '72px'],
  'lightning-box': ['100px', '100px'],
  'max-win-gaming': ['100px', '100px'],
  microgaming: ['72px', '88px'],
  'micro-sub-provider': ['72px', '88px'],
  'net-ent': ['48px', '56px'],
  'next-gen': ['48px', '56px'],
  'northern-lights': ['100px', '100px'],
  'no-limit-city': ['56px', '72px'],
  'nolimit-city': ['56px', '72px'],
  onetouch: ['100px', '100px'],
  oryx: ['100px', '100px'],
  pariplay: ['100px', '100px'],
  'pg-soft': ['100px', '100px'],
  'playn-go': ['56px', '72px'],
  playngo: ['56px', '72px'],
  'plank-gaming': ['100px', '100px'],
  playson: ['64px', '80px'],
  'pragmatic-play': ['64px', '80px'],
  'push-gaming': ['80px', '96px'],
  pushgaming: ['80px', '96px'],
  rabcat: ['100px', '100px'],
  realistic: ['100px', '100px'],
  'red-rake': ['100px', '100px'],
  'reel-play': ['81px', '81px'],
  'relax-gaming': ['100px', '100px'],
  revolver: ['100px', '100px'],
  'real-dealer': ['64px', '80px'],
  'red-tiger': ['80px', '96px'],
  'sapphier-gaming': ['100px', '100px'],
  'sg-gaming': ['100px', '100px'],
  'skywind-group': ['100px', '100px'],
  stakelogic: ['100px', '100px'],
  sthlmgaming: ['100px', '100px'],
  'storm-gaming': ['100px', '100px'],
  'stormcraft-studios': ['100px', '100px'],
  'switch-studios': ['59px', '59px'],
  'shuffle-master': ['56px', '72px'],
  slingshot: ['56px', '72px'],
  'slingshot-studios': ['56px', '72px'],
  thunderkick: ['64px', '80px'],
  'triple-edge-studios': ['92px', '92px'],
  wazdan: ['100px', '100px'],
  'wild-streak-gaming': ['100px', '100px'],
  wms: ['100px', '100px'],
  yggdrasil: ['64px', '80px'],
}

export const GameTileProviderLogosWidthsDefault = ['48px', '64px']

export const GameTileWidth = {
  LARGE: 353,
  MEDIUM: 249,
  SMALL: 152,
}

export const isDesktop =
  process.browser && matchMedia('(min-width: 800px)').matches

export const noJapaneeseCharactersPattern =
  '^((?![\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B).)+$'

export const PasswordPattern =
  "^(?=.*[a-z])(?=.*\\d)(?=.*[@$!%*?#\\-+\\/'^:,(){}[\\]~`_.])[A-Za-z\\d@$!%*?#\\-+\\/'^:,(){}[\\]~`_.]{8,}$"

export const SelfExclusionSteps = {
  Initial: 0,
  Confirmation: 1,
}

export const TimeoutSteps = {
  Initial: 0,
  Confirmation: 1,
}

export const TransactionStep = {
  Amount: 0,
  Payer: 1,
}

export const RegistrationStep = {
  Credentials: 0,
  Identity: 1,
}

// TODO: import from payer when available
export const TransactionType = {
  AUTH: 'auth',
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
}

export const DEFAULT_LIMITS_MAPPING = {
  deposit: {
    max: 1000000,
    min: 5000,
  },
  withdrawal: {
    max: 1000000,
    min: 10000,
  },
}
