import * as ReduxEffects from 'redux-effects'
import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Common from '@rushplay/common'
import * as I18n from '@rushplay/i18n'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

import * as Constants from './constants'
import * as Theming from './theming'
import * as Cookies from './cookies'
import { Button } from './button'
import { HtmlContent } from './html-content'

const popUpAnimation = keyframes`
  0% {
   opacity: 0;
   transform: translateY(20px);
  }

  100% {
   opacity: 1;
   transform: translateY(0);
  }
`

const ConsentContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 9999;
  opacity: 0;
  transform: translateY(20px);

  &.animated {
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: ${popUpAnimation};
    animation-delay: 0.7s;
  }
`

export function CookieConsentBanner() {
  const [visible, setVisible] = React.useState(false)
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    dispatch(
      ReduxEffects.bind(
        Cookies.get(Constants.CookieKeys.COOKIE_CONSENT),
        value => {
          setVisible(!value)
        }
      )
    )
  }, [])

  function handleSubmit() {
    setVisible(false)
    dispatch(
      Cookies.set('cookie-consent', true, {
        // Consent must be given at minimum every year, hence the 1 year cookie age
        maxAge: 365 * 24 * 60 * 60 * 1000,
        httpOnly: false,
        path: '/',
      })
    )
  }
  return (
    <Theming.Alternative>
      {visible && (
        <ConsentContainer className="animated">
          <Common.Box
            width="100%"
            backgroundColor="cookie-banner-background"
            color="g-text"
            boxShadow={0}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderTop="1px solid #efefef"
            py={0}
            px={1}
            fontSize={[0, 1]}
          >
            <HtmlContent
              html={{ __html: i18n.translate('cookie-disclaimer') }}
            />
            <Common.Space pl={0}>
              <Button fontSize={1} onClick={handleSubmit}>
                {i18n.translate('okey')}
              </Button>
            </Common.Space>
          </Common.Box>
        </ConsentContainer>
      )}
    </Theming.Alternative>
  )
}
