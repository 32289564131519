import * as R from 'ramda'
import * as DateFns from 'date-fns'

import * as Herz from '@rushplay/herz'
import * as Api from '@rushplay/api-client'
import * as Websockets from '@rushplay/websockets'

import * as Utils from './utils'

// Constants
export const CLEAR = 'promotions/CLEAR'
export const MARK_ALL_AS_SEEN = 'promotions/MARK_ALL_AS_SEEN'
export const MARK_AS_SEEN = 'promotions/MARK_AS_SEEN'
export const REMOVE = 'promotions/REMOVE'
export const UPDATE = 'promotions/UPDATE'
export const UPDATE_ALL = 'promotions/UPDATE_ALL'
export const OPT_IN = 'promotions/OPT_IN'
export const OPT_OUT = 'promotions/OPT_OUT'
export const OPT_OUT_ALL = 'promotions/OPT_OUT_ALL'

// Actions
export function clear() {
  return { type: CLEAR }
}

export function markAllAsSeen() {
  return { type: MARK_ALL_AS_SEEN }
}

export function markAsSeen(key) {
  return {
    type: MARK_AS_SEEN,
    payload: key,
  }
}

export function remove(key) {
  return {
    type: REMOVE,
    payload: key,
  }
}

export function updateAll(payload) {
  return {
    type: UPDATE_ALL,
    payload: R.reduce(
      (acc, item) =>
        R.assoc(
          R.path(['key'], item),
          R.pick(
            [
              'activeFrom',
              'activeTo',
              'campaign',
              'gameTitle',
              'key',
              'loggedInButtonLink',
              'name',
              'previewFrom',
              'previewTo',
              'seen',
            ],
            item
          ),
          acc
        ),
      {},
      payload
    ),
  }
}

export function update(promotion) {
  return {
    type: UPDATE,
    payload: promotion,
  }
}

export function optIn(promotionkey) {
  return {
    type: OPT_IN,
    payload: promotionkey,
  }
}

export function optOut(promotionkey) {
  return {
    type: OPT_OUT,
    payload: promotionkey,
  }
}

export function optOutOfAll() {
  return Api.optOutAllDepositCampaigns({
    success: () => ({ type: OPT_OUT_ALL }),
    version: 2,
  })
}

// You need to manually send country-code if you wish to fetch promotions logged out.
export function fetch(countryCode) {
  return Api.fetchPromotions(countryCode, {
    success: res => updateAll(res.value),
    version: 2,
  })
}

// Reducer
export function reducer(state = {}, action) {
  switch (action.type) {
    case Websockets.SESSION_EXPIRED:
    case CLEAR: {
      return {}
    }

    case MARK_ALL_AS_SEEN: {
      return Utils.Objects.mapAssoc('seen', true, state)
    }

    case MARK_AS_SEEN: {
      return R.assocPath([action.payload, 'seen'], true, state)
    }

    case Websockets.PROMOTION_ENDED:
    case Websockets.PROMOTION_PREVIEW_ENDED:
    case REMOVE: {
      return R.dissoc(action.payload, state)
    }

    case UPDATE_ALL: {
      return action.payload
    }

    case Websockets.PROMOTION_STARTED:
    case Websockets.PROMOTION_PREVIEW_STARTED:
    case UPDATE: {
      return R.assoc(R.path(['key'], action.payload), action.payload, state)
    }

    case OPT_IN: {
      return R.assocPath(
        [action.payload, 'campaign', 'optInState'],
        'in',
        state
      )
    }

    case OPT_OUT: {
      return R.assocPath(
        [action.payload, 'campaign', 'optInState'],
        'out',
        state
      )
    }

    case OPT_OUT_ALL: {
      // Change all opted 'in' campaigns to 'out'
      return R.reduce(
        (acc, key) => {
          if (R.path([key, 'campaign', 'optInState'], acc) === 'in') {
            return R.assocPath([key, 'campaign', 'optInState'], 'out', acc)
          }
          return acc
        },
        state,
        R.keys(state)
      )
    }

    default:
      return state
  }
}

// Selectors
export function getDepositCampaigns(state, props) {
  return R.reject(
    promotion =>
      R.isNil(promotion.campaign) ||
      !R.pathEq(['campaign', 'rewardTrigger'], 'deposit', promotion) ||
      props.dateNow < DateFns.getTime(DateFns.parseISO(promotion.activeFrom)) ||
      R.pathEq(['campaign', 'claimed'], true, promotion) ||
      R.isEmpty(R.pathOr([], ['campaign', 'rewards'], promotion)),
    state
  )
}

export function getOptedInDepositCampaigns(state, props) {
  return R.filter(
    item =>
      R.any(R.equals(R.path(['campaign', 'optInState'], item)), [
        'in',
        'automatic',
      ]),
    getDepositCampaigns(state, props)
  )
}

export function getActivePromotions(state, props) {
  return R.pipe(
    R.reject(
      promotion =>
        props.dateNow <= DateFns.getTime(DateFns.parseISO(promotion.activeFrom))
    ),
    R.values
  )(state)
}

export function getFuturePromotions(state, props) {
  return R.pipe(
    R.reject(
      promotion =>
        props.dateNow > DateFns.getTime(DateFns.parseISO(promotion.activeFrom))
    ),
    R.values
  )(state)
}

export function hasUnseenPromotions(state) {
  return R.findIndex(R.pathEq(['seen'], false), R.values(state)) >= 0
}

// Helpers

/**
 * Generates a translation key for the reward
 * @param {Object} reward
 * @returns {String} translation key
 */
export function getRewardTranslationKey(reward) {
  if (reward.blitz) {
    return `promotion.${Herz.Utils.Strings.toKebabCase(reward.kind)}.blitz`
  }
  return `promotion.${Herz.Utils.Strings.toKebabCase(reward.kind)}`
}

/**
 * Generates translation variables needed for a particular reward content
 * @param {Object} reward
 * @param {number} amountCents currently selected deposit amount
 * @returns {Object} translation variables
 */
export function getRewardTranslationVariables(reward, amountCents) {
  return R.pipe(
    R.pick(['amount', 'betAmountCents', 'gameTitle', 'maximumAmountCents']),
    R.mergeLeft({
      rewardAmountCents: R.min(
        (amountCents * reward.amount) / 100,
        R.path(['maximumAmountCents'], reward)
      ),
    }),
    R.reject(R.isNil)
  )(reward)
}

/**
 * Checks if the reward is disabled for currently selected deposit amount
 * @param {Object} reward
 * @param {number} amountCents currently selected deposit amount
 * @returns {Boolean}
 */
export function isRewardDisabled(reward, amountCents) {
  const depositAmountFromCents = R.path(
    ['depositAmountFilter', 'deposit_amount_from_cents'],
    reward
  )
  const depositAmountToCents = R.path(
    ['depositAmountFilter', 'deposit_amount_to_cents'],
    reward
  )
  return (
    (depositAmountFromCents && amountCents < depositAmountFromCents) ||
    (depositAmountToCents && amountCents > depositAmountToCents)
  )
}

/**
 * Generates minimum deposits needed to claim at least 1 reward in promotion,
 * minimum deposit to fully claim all rewards in promotion and
 * maximum possible rewards in promotion
 * @param {Object} rewards all rewards available in a promotion
 * @returns {Object}
 */
export function getPromotionMinMax(rewards) {
  return {
    minDepositForFullPackageCents: R.reduce(
      (result, item) => {
        if (
          R.includes(R.path(['kind'], item), [
            'MoneyPercentItem',
            'BonusPercentItem',
          ])
        ) {
          // Calculating minimum deposit needed to claim maximumAmountCents
          const minDepositForMaxAmount =
            R.path(['maximumAmountCents'], item) /
            (0.01 * R.path(['amount'], item))
          // Minimum deposit to claim full reward of this kind would be either
          // minimum deposit to claim maximumAmountCents or
          // minimum deposit to claim this reward at all
          const min = R.max(
            minDepositForMaxAmount,
            R.pathOr(
              0,
              ['depositAmountFilter', 'deposit_amount_from_cents'],
              item
            )
          )
          return R.max(min, result)
        }
        return R.max(
          result,
          R.path(['depositAmountFilter', 'deposit_amount_from_cents'], item)
        )
      },
      0,
      rewards
    ),
    minDepositForAnyReward: R.reduce(
      (result, item) =>
        R.min(
          result,
          R.pathOr(
            0,
            ['depositAmountFilter', 'deposit_amount_from_cents'],
            item
          )
        ),
      Infinity,
      rewards
    ),
    maxFixedFeatureTriggers: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'FeatureTriggers'
          ? R.max(result, R.path(['amount'], item))
          : result,
      0,
      rewards
    ),
    maxFixedFreespins: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'Freespins'
          ? R.max(result, R.path(['amount'], item))
          : result,
      0,
      rewards
    ),
    maxFixedFreebet: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'Freebet'
          ? R.max(result, R.path(['amount'], item))
          : result,
      0,
      rewards
    ),
    maxFixedBonusItem: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'BonusItem'
          ? R.max(result, R.path(['amount'], item))
          : result,
      0,
      rewards
    ),
    maxFixedMoneyItem: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'MoneyItem'
          ? R.max(result, R.path(['amount'], item))
          : result,
      0,
      rewards
    ),
    maxBonusPercentItem: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'BonusPercentItem'
          ? R.max(result, R.path(['maximumAmountCents'], item))
          : result,
      0,
      rewards
    ),
    maxMoneyPercentItem: R.reduce(
      (result, item) =>
        R.path(['kind'], item) === 'MoneyPercentItem'
          ? R.max(result, R.path(['maximumAmountCents'], item))
          : result,
      0,
      rewards
    ),
  }
}

/**
 * Generates translation variables needed for a particular Promotion content.
 * (Used in promotions drawer where we don't have a reward split for content)
 * Will be generated for each reward:
 * - rangeLimits_N - minimum deposit to get this reward
 * - gameTitle_N - game title for this reward, if applicable
 * (for Feature triggers and Freespins)
 * - maximumAmountCents_N - maximum amount cents for the reward, if applicable
 * (For Bonus Percent Item and Money Percent Item)
 * - rewardKind_N - the amount of the appropriate reward
 * @param {Object} rewards all rewards available in a promotion
 * @returns {Object} translation variables
 *
 * @example
 *   {
 *      rangeLimits_1: 1000,
 *      rangeLimits_2: 5000,
 *      gameTitle_1: "Starburst",
 *      gameTitle_2: "Note of Death",
 *      freespins_1: 10,
 *      freespins_2: 30,
 *    }
 */

export function getPromotionTranslationVariables(rewards) {
  const mapIndexed = R.addIndex(R.map)
  return R.pipe(
    mapIndexed((reward, index) => {
      return {
        [`rangeLimits_${index + 1}`]: R.pathOr(
          0,
          ['depositAmountFilter', 'deposit_amount_from_cents'],
          reward
        ),
        [`${R.toLower(R.path(['kind'], reward))}_${index + 1}`]: R.path(
          ['amount'],
          reward
        ),
        [`maximumAmountCents_${index + 1}`]: R.path(
          ['maximumAmountCents'],
          reward
        ),
        [`gameTitle_${index + 1}`]: R.path(['gameTitle'], reward),
      }
    }),
    R.mergeAll,
    R.reject(R.isNil)
  )(rewards)
}

/**
 * Returns selected promotion
 * @param {Object} props
 * @returns {Object} promotion
 */

export function getPromotionByKey(state, props) {
  return R.prop(props.key, state)
}
