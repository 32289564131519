import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as i18n from '@rushplay/i18n'
import { toAmount } from '@rushplay/common'

import * as Constants from '../constants'
import * as ServerConfiguration from '../server-configuration'
import { currencies } from '../amount'

// TODO: Handle case in @rushplay/i18n
function createKeyResolver(language) {
  return function keyResolver(keys, key) {
    if (typeof key === 'string') {
      const normalizedKey = `${language}:${Constants.Brand}.${key}`
      const caseInsensitiveKey = R.toLower(normalizedKey)

      if (R.includes(caseInsensitiveKey, keys)) {
        return caseInsensitiveKey
      }

      return normalizedKey
    }
  }
}

function stateResolver(state) {
  return state.i18n
}

const processors = {
  currency(value, data) {
    const currency = currencies[data.currencyCode]

    if (process.env.NODE_ENV !== 'production') {
      if (currency == null) {
        // eslint-disable-next-line no-console
        console.error(`"${data.currencyCode}" is an unknown currency`)
      }
    }

    return toAmount({ currency, locale: data.locale }, value)
  },
}

export function Provider(props) {
  const configuration = ServerConfiguration.useContext()

  const translationsUrl = React.useMemo(() => {
    const cdnUrl = `${configuration.cdnHost}/${configuration.cdnPrefix}`
    const filename = `amuletobet_${configuration.locale.code}`
    return `${cdnUrl}/locales/${filename}.json`
  }, [configuration])

  const variableResolver = React.useCallback(() => {
    return {
      brandEmail: configuration.supportEmail,
      brandName: 'AmuletoBet',
      currencyCode: configuration.currency.code,
      locale: configuration.locale.slug,
    }
  }, [configuration])

  return (
    <i18n.TranslationProvider
      keyResolver={createKeyResolver(R.toLower(configuration.locale.code))}
      language={R.toLower(configuration.locale.code)}
      processors={processors}
      stateResolver={stateResolver}
      url={translationsUrl}
      variableResolver={variableResolver}
    >
      {props.children}
    </i18n.TranslationProvider>
  )
}

Provider.propTypes = {
  children: PropTypes.element,
  language: PropTypes.string,
  url: PropTypes.string,
}
