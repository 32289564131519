/**
 * Only replaces specified queries
 * @param {Object} nextQuery
 * @returns {string} querystring with replaced values
 */

export function useSafeReplaceQuery(nextQuery) {
  Object.keys(nextQuery).forEach(key => {
    if (!nextQuery[key]) {
      delete nextQuery[key]
    }
  })

  return new URLSearchParams(nextQuery).toString()
}
